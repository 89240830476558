/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';
import './src/styles/theme-toggle.css';
import './src/styles/scroll.css';
import './src/styles/backgroundShadows.css';
import './src/styles/banner.css';
import './src/styles/glassMorphismCards.css';